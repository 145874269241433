<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- DenTyme logo-->
      <dentyme-logo />
      <!-- /DenTyme logo-->

      <!-- Left Text-->
      <b-col
        lg="7"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <dentyme-info-slider />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="5"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
            v-html="$t(`welcome`)"
          />
          <b-card-text class="mb-2">
            {{ $t(`login.please_sign_in`) }}
          </b-card-text>

          <b-alert
            variant="primary"
            :show="false"
          >
            <div class="alert-body font-small-2">
              <p>
                <small class="mr-50"><span class="font-weight-bold">Admin:</span> admin@demo.com | admin</small>
              </p>
              <p>
                <small class="mr-50"><span class="font-weight-bold">Client:</span> client@demo.com | client</small>
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'This is just for ACL demo purpose'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10; right: 10;"
            />
          </b-alert>

          <!-- form -->
          <login-form />
          <!-- /form -->

          <b-card-text
            class="text-center mt-2"
            v-html="$t(`login.disclaimer`)"
          >
            <!-- <span>New on our platform? </span>
            <b-link :to="{name:'auth-register'}">
              <span>&nbsp;Create an account</span>
            </b-link> -->
          </b-card-text>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div> -->

          <!-- social buttons -->
          <!-- <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import DentymeLogo from '@/views/components/dentyme-logo/DentymeLogo.vue'
import {
  BRow, BCol, BCardText, BCardTitle, BAlert, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store/index'
import LoginForm from '@/views/components/forms/LoginForm.vue'
import DentymeInfoSlider from '@/views/components/dentyme-info-slider/DentymeInfoSlider.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BAlert,
    DentymeInfoSlider,
    DentymeLogo,
    LoginForm,
  },
  data() {
    return {
      sideImg: require('@/assets/images/pages/login-v2.svg'),
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
  },
  metaInfo() {
    return { title: this.$t('page_titles.login'), titleTemplate: 'DenTyme - %s' }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@/assets/scss/variables/_variables.scss';
.brand-text {
  color: $primary;
}
</style>
