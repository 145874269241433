<template>
  <validation-observer
    ref="loginForm"
    #default="{invalid}"
  >
    <b-overlay
      :show="loading"
      :variant="overlayVariant"
      :opacity="overlayOpacity"
      rounded="sm"
    >
      <b-form
        class="auth-login-form mt-2"
        @submit.prevent="login"
      >
        <!-- email -->
        <b-form-group
          :label="$t(`login.email_placeholder`)"
          label-for="login-email"
        >
          <validation-provider
            #default="{ errors }"
            :name="$t(`login.email_placeholder`)"
            vid="email"
            rules="required|email"
          >
            <b-form-input
              id="login-email"
              v-model="user.email"
              :state="errors.length > 0 ? false:null"
              name="login-email"
              data-vv-validate-on="blur"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <div class="d-flex justify-content-between">
            <label for="login-password">{{ $t(`login.password_placeholder`) }}</label>
          </div>
          <validation-provider
            #default="{ errors }"
            :name="$t(`login.password_placeholder`)"
            vid="password"
            rules="required"
          >
            <b-input-group
              class="input-group-merge"
              :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="login-password"
                v-model="user.password"
                :state="errors.length > 0 ? false:null"
                class="form-control-merge"
                :type="passwordFieldType"
                name="login-password"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="passwordToggleIcon"
                  @click="togglePasswordVisibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- forgot password -->
        <b-form-group>
          <b-link :to="{name:'auth-forgot-password'}">
            {{ $t(`login.forgot_password`) }}
          </b-link>
        </b-form-group>

        <!-- server alert -->
        <b-alert
          v-height-fade.appear
          variant="danger"
          :show="serverError !== null"
        >
          <div
            class="alert-body"
          >
            <feather-icon
              icon="InfoIcon"
              class="mr-50"
            />
            <span v-html="serverError" />
          </div>
        </b-alert>

        <!-- submit buttons -->
        <b-button
          type="submit"
          variant="primary"
          block
          :disabled="invalid || loading"
        >
          <!-- <b-spinner
            v-show="loading"
            small
          /> -->
          <span
            v-if="!loading"
          >{{ $t(`login.sign_in`) }}</span>
          <span
            v-else
          >{{ $t('login.signing_in') }}</span>
        </b-button>
      </b-form>
    </b-overlay>
  </validation-observer>
</template>

<script>
import {
  BAlert,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BForm,
  BButton,
  BOverlay,
} from 'bootstrap-vue'
import {
  heightFade,
} from '@core/directives/animations'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import {
  required,
  email,
} from '@validations'
import {
  togglePasswordVisibility,
} from '@core/mixins/ui/forms'
import {
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { initialAbility } from '@/libs/acl/config'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BForm,
    BButton,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    'height-fade': heightFade,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      user: {
        email: null,
        password: null,
      },
      // validation rules
      required,
      email,
      serverError: null,
      loading: false,
      overlayOpacity: 0.75,
      overlayVariant: 'white',
    }
  },
  computed: {
    ...mapGetters('auth', ['activeUser']),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    ...mapActions('auth', ['loginUser']),
    login() {
      this.$refs.loginForm.validate().then(async success => {
        if (success) {
          this.serverError = null
          this.loading = true
          try {
            this.user.email = this.user.email.toLowerCase()
            await this.loginUser(this.user) // await useJwt.login(this.user)
            const { currentRole } = this.activeUser
            this.$ability.update(this.activeUser.ability[currentRole.name] || initialAbility)
            this.$router.replace(getHomeRouteForLoggedInUser(this.activeUser.currentRole.name, true)).then(() => {

            })
              .catch(error => {
                console.debug(`Redirect error after login: ${error.message}`)
                // this.serverError = this.$t('errors.generic')
              })
              .finally(
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Welcome ${this.activeUser.first_name}`,
                    icon: 'BellIcon',
                    variant: 'success',
                    text: `You have successfully logged in as ${this.activeUser.currentRole.description}.`,
                  },
                }),
              )
          } catch (error) {
            console.error(`Login Error: ${error.message}`)
            if (error.response) {
              if (error.response.status < 500) {
                this.serverError = error.response.data.message
              } else {
                this.serverError = this.$t('errors.generic')
              }
            } else {
              this.serverError = this.$t('errors.generic')
            }
          } finally {
            this.loading = false
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
#login-email {
  text-transform: lowercase;
}
</style>
